import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SignUp from "../../components/signup/SignUp";
import l1feMinistry from "./../../assets/images/l1feMinistry.svg";
import { useNavigate } from "react-router-dom";
import LeftColoumn from "../../components/shared/LeftColoumn";

const SignupPage = () => {
  const navigation = useNavigate();

  return (
    <>
      <Container fluid className="vh-100 login-wrapper wrapper">
        <Row className="h-100">
          <LeftColoumn />
          <Col xl={7} className="right-col mt-5">
            <Container className="h-100">
              <Row className="h-100">
                <Col lg={{ span: 6, offset: 3 }} className="auth-block">
                  <div className="w-100 mb-5">
                    <h2 className="text-dark">Socialize without censorship</h2>
                    <div className="tab-header">
                      <div className="head">
                        <div
                          className="tab"
                          onClick={() => navigation("/login")}
                        >
                          Log In
                        </div>
                        <div
                          className={"active tab"}
                          onClick={() => navigation("/signup")}
                        >
                          Create Account
                        </div>
                      </div>
                    </div>
                    <SignUp />
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <div className="position-fixed ministry-logo ">
            <img src={l1feMinistry} width={142} alt="l1fe ministy" />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SignupPage;
